import request from 'common/utils/axios-request';
import { UserFormType } from 'pages/UsersPage/UsersCreateEdit/types/UserFormType';

import { Project } from './types/Project';
import { User } from './types/User';

type Input = {
  readonly page: number;
  readonly pageSize: number;
  readonly username?: string;
  readonly surname?: string;
  readonly roleId?: string;
  readonly projectId?: string;
};

export type UsersInput = Input;

type UsersQuery = {
  readonly page: number;
  readonly size: number;
  readonly username?: string;
  readonly surname?: string;
  readonly role_id?: string;
  readonly project_id?: string;
};

type GetResult = {
  readonly list: readonly User[];
  readonly totalCount: number;
};

export type PasswordPolicy = {
  readonly max_size: number;
  readonly min_size: number;
  readonly regex: string;
};

export type UsersResult = GetResult;

const parseQuery = (input: Input): UsersQuery => ({
  page: input.page,
  size: input.pageSize,
  username: input.username,
  surname: input.surname,

  role_id: input.roleId,

  project_id: input.projectId,
});
export function usersGet(data: Input): Promise<GetResult> {
  return request('/api/v1/users', {
    method: 'GET',
    params: parseQuery(data),
  }).then(({ data, headers }) => {
    return {
      list: data as readonly User[],
      totalCount: parseInt(headers['x-total-count']),
    };
  });
}

export function userGet(userId: number | string): Promise<User> {
  return request(`/api/v1/users/${userId}`, {
    method: 'GET',
  }).then(({ data }) => {
    return data as User;
  });
}

export function userPost(data: UserFormType): Promise<User> {
  return request('/api/v1/users', {
    method: 'POST',
    data: data,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then(({ data }) => data as User);
}

export function userPut(data: UserFormType, userId: number): Promise<User> {
  return request('/api/v1/users', {
    method: 'PUT',
    data: { ...data, id: userId },
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then(({ data }) => data as User);
}

export function userDelete(id: number): Promise<unknown> {
  return request('/api/v1/users/' + id, {
    method: 'DELETE',
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then(({ data }) => data);
}

export function userProjectsPost(userId: number, projects: readonly Project[]): Promise<unknown> {
  return request(`/api/v1/users/${userId}/projects`, { method: 'POST', data: projects }).then(
    (res) => res
  );
}

export function userChangePassword(oldPassword: string, newPassword: string): Promise<unknown> {
  const passwords = {
    old_password: oldPassword,
    new_password: newPassword,
  };
  return request('/api/v1/me/change-pwd', { method: 'PUT', data: passwords }).then((res) => res);
}

export function passwordPolicyGet(): Promise<PasswordPolicy> {
  return request('/api/v1/settings/client/PASSWORD_POLICY').then(
    ({ data }) => data.json as PasswordPolicy
  );
}
